body {
  color: #eee;
}
.friend {
  border: 1px solid #ccc;
  padding: 20px;
}
.friend.checked {
  background: #ddd;
}

.fb-post {
  width: 100%;
  margin-bottom: 20px;
}

.feed {
  height: calc(100vh - 80px);
  overflow-y: auto;
}